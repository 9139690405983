import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container">
        <Link className="navbar-brand" aria-current="page" to="/">
          Michael Dawson-Haggerty
        </Link>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a
              className="nav-link active"
              aria-current="page"
              target="_blank"
              href="/static/michael.dawson_haggerty.resume.pdf"
            >
              RESUME
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active"
              aria-current="page"
              target="_blank"
              href="https://github.com/mikedh"
            >
              GITHUB
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};
