import React from "react";

import { carousel } from "bootstrap/js/src/dom";

export default () => (
  <header className="masthead">
    <div className="container">
      <div className="header-content">
        <p>
          I'm a robotics engineer interested in building systems that convert
          mechanical problems into software problems.
        </p>
      </div>
      <div
        id="carouselExampleSlidesOnly"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item iframe-container active">
            <iframe
              className="responsive-iframe"
              src="https://www.youtube.com/embed/GlsLzbYjTj0?controls=0"
              allowFullScreen={true}
            ></iframe>
          </div>
          <div className="carousel-item iframe-container ">
            <iframe
              className="responsive-iframe"
              src="https://www.youtube.com/embed/GlsLzbYjTj0?controls=0"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </header>
);
