import React from "react";

export default () => {
  return (
    <footer className="footer fixed-bottom navbar-inverse" role="contentinfo">
      <div className=" align-center">
      <p></p>
      </div>
    </footer>
  );
};
